import { Check } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { API_ROOT } from '../constants/constants';

interface BooksMenuProps {
  anchorEl: null | HTMLElement;
  menuId: string;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  setBook: (title: null | string) => void;
  selectedBookTitle: null | string;
}

type Book = {
  title: null | string,
  author: string
}

interface SelectableMenuItemProps {
  item: Book,
  selectedBookTitle: null | string,
  onClick: (title: null | string) => void
}

function SelectableMenuItem(props: SelectableMenuItemProps) {

  return ((props.selectedBookTitle == props.item.title || (!props.selectedBookTitle && !props.item.title)) ? (
    <MenuItem onClick={() => props.onClick(props.item.title)} key={props.item.title}>
      <ListItemIcon>
        <Check />
      </ListItemIcon>
      {props.item.title}: {props.item.author}
    </MenuItem>
  ) : (
    <MenuItem onClick={() => props.onClick(props.item.title)} key={props.item.title}>
      <ListItemText inset>
        {props.item.title}: {props.item.author}
      </ListItemText>
    </MenuItem>
  ))
}


export default function BooksMenu(props: BooksMenuProps) {

  const [items, setItems] = useState<Book[] | null>(null);
  const ALL_BOOKS_ITEM = { title: null, author: "All Books" };

  function getAllBooks() {
    fetch(API_ROOT + "books")
      .then(res => res.json() as Promise<Book[]>)
      .then(
        (result) => {
          result.unshift(ALL_BOOKS_ITEM);
          setItems(result);
        },
        (error: Error) => {
          setItems([ALL_BOOKS_ITEM]);
          console.log(error);
        }
      )
  }

  useEffect(() => {
    getAllBooks()
  }, [])

  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={props.menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={props.isMenuOpen}
      onClose={props.handleMenuClose}
    >
      {
        items?.map((item) => <SelectableMenuItem item={item} onClick={props.setBook} selectedBookTitle={props.selectedBookTitle} />)
      }

    </Menu>
  );
}