import './App.css';
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth, Unsubscribe, User } from "firebase/auth";
import RandomClip from './components/RandomClip';
import Login from './components/Login';
import ButtonAppBar from './components/TopAppBar';
import useStateParams from './utils/UseQueryState';
import { Route, Routes } from 'react-router-dom';

type PropsType = {}

type StateType = {
  currentUser: User | null,
  selectedBookTitle: string | null
}

const firebaseConfig = {
  apiKey: "AIzaSyCHg7TYqbqM-z_qMkebrnQHQVSNH0QEYTU",
  authDomain: "jurajkusnier-test.firebaseapp.com",
  projectId: "jurajkusnier-test",
  storageBucket: "jurajkusnier-test.appspot.com",
  messagingSenderId: "842928081601",
  appId: "1:842928081601:web:16200baafc690c2bd7a240"
}

export default function App() {

  const [selectedBookTitle, setSelectedBookTitle] = useStateParams(null, "book", (s: string|null) => s, (s: string|null) => s)

  return (
    // let currentUser = this.state.currentUser;
    // if (currentUser == null) {
    // return (<div>
    // <Login />
    // </div>)
    // } else {
    <Routes>
      <Route path='/' element={
        <div>
          <ButtonAppBar selectedBookTitle={selectedBookTitle} setSelectedBookTitle={(title) => setSelectedBookTitle(title ? title : "")} />
          <RandomClip selectedBookTitle={selectedBookTitle} />
        </div>
      }>
      </Route>
    </Routes>
    // }
  )
};