import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function useStateParams<T>(
    initialState: T|null,
    paramsName: string,
    serialize: (state: T | null) => string | null,
    deserialize: (state: string | null) => T | null
): [T | null, (state: T | null) => void] {
    const navigate = useNavigate();

    const search = new URLSearchParams(window.location.search);

    const existingValue = search.get(paramsName);
    const [state, setState] = useState<T | null>(
        existingValue ? deserialize(existingValue) : initialState
    );

    useEffect(() => {
        // Updates state when user navigates backwards or forwards in browser history
        if (existingValue && deserialize(existingValue) !== state) {
            setState(deserialize(existingValue));
        }
    }, [existingValue]);

    const onChange = (s: T | null) => {
        setState(s);
        const searchParams = new URLSearchParams(window.location.search);
        if (s) {
            const serialized = serialize(s);
            searchParams.set(paramsName, serialized as string);
        } else {
            searchParams.delete(paramsName);
        }
        const pathname = window.location.pathname;
        navigate(pathname + "?" + searchParams.toString());

    };

    return [state, onChange];
}