import { Alert, Button, Card, CardActions, Skeleton, Typography } from '@mui/material';
import { useState, useEffect } from 'react'
import Grid from "@mui/material/Grid";
import CardContent from '@mui/material/CardContent';
import React from 'react';
import { API_ROOT } from '../constants/constants';

interface RandomClipProps {
    selectedBookTitle: string | null
}

function getRandomClipUrl(selectedBookTitle: string | null) {
    if (selectedBookTitle) {
        return API_ROOT + "randomClip?title=" + selectedBookTitle
    } else {
        return API_ROOT + "randomClip"
    }
}

function getAllClipsUrl(selectedBookTitle: string | null) {
    if (selectedBookTitle) {
        return API_ROOT + "clips?title=" + selectedBookTitle
    } else {
        return API_ROOT + "clips"
    }
}

enum IDs {
    PREV = "PREV", NEXT = "NEXT", RANDOM = "RANDOM", ALL = "ALL"
}

function RandomClip(props: RandomClipProps) {
    const [error, setError] = useState<Error | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState<Clip | null>(null);

    type Clip = {
        id: number,
        author: string;
        title: string;
        content: string;
    }

    function getPrevClipUrl() {
        let id = item?.id ?? 0
        return API_ROOT + "clip/" + (id - 1)
    }

    function getNextClipUrl() {
        let id = item?.id ?? 0
        return API_ROOT + "clip/" + (id + 1)
    }

    function getClip(url: string) {
        setIsLoaded(false);
        fetch(url)
            .then(res => res.json() as Promise<Clip>)
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItem(result);
                    setError(null);
                },
                (error: Error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    function getAllClips(url: string) {
        setIsLoaded(false);
        fetch(url)
            .then(res => res.json() as Promise<Clip[]>)
            .then(
                (results) => {
                    setIsLoaded(true);

                    var reducedContent = ""
                    for (const result of results) {
                        reducedContent += result.content + "\n\n"
                    }

                    setItem({ id: 0, author: results[0].author, title: results[0].title, content: reducedContent });
                    setError(null);
                },
                (error: Error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    useEffect(() => {
        getClip(getRandomClipUrl(props.selectedBookTitle))
    }, [])

    function handleClick(e: React.MouseEvent<any>) {
        e.preventDefault();
        switch (e.currentTarget.id) {
            case IDs.ALL:
                getAllClips(getAllClipsUrl(props.selectedBookTitle));
                break;
            case IDs.NEXT:
                getClip(getNextClipUrl());
                break;
            case IDs.PREV:
                getClip(getPrevClipUrl());
                break;
            case IDs.RANDOM:
                getClip(getRandomClipUrl(props.selectedBookTitle));
                break;
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={0} sm={12}></Grid>
            <Grid item xs={0} sm={2} md={3}></Grid>
            <Grid item xs={12} sm={8} md={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant='h5' gutterBottom component="div">
                            {
                                (isLoaded || item != null) ? (item?.author + ": " + item?.title) :
                                    <Skeleton animation="wave" />
                            }
                        </Typography>
                        <Typography variant='body1' style={{whiteSpace: 'pre-line'}}>
                            {
                                (isLoaded || item != null) ? (item?.content) :
                                    (
                                        <React.Fragment>
                                            <Skeleton animation="wave" variant="text" height={20} />
                                            <Skeleton animation="wave" variant="text" height={20} />
                                            <Skeleton animation="wave" variant="text" height={20} width="80%" />
                                        </React.Fragment>
                                    )
                            }
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button id={IDs.PREV} size="small" onClick={handleClick}>Prev</Button>
                        <Button id={IDs.RANDOM} size="small" onClick={handleClick}>Random</Button>
                        <Button id={IDs.ALL} size="small" onClick={handleClick}>All</Button>
                        <Button id={IDs.NEXT} size="small" onClick={handleClick}>Next</Button>
                    </CardActions>
                </Card>
                {error && <Alert severity="error">{error.message}</Alert>}
            </Grid>
            <Grid item xs={0} sm={2} md={3}></Grid>
        </Grid>
    );
}

export default RandomClip

// Auto reformat code [SHIFT] + [OPTION] + [F]
// Typescript documentation: https://www.typescriptlang.org/docs/handbook/2/everyday-types.html
