import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { render } from "@testing-library/react";

interface AccountMenuProps {
    anchorEl: null | HTMLElement;
    menuId: string;
    isMenuOpen: boolean;
    handleMenuClose: () => void;
    handleLogout: () => void;
}

export default function AccountMenu(props: AccountMenuProps) {

    return (
        <Menu
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={props.menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.isMenuOpen}
            onClose={props.handleMenuClose}
        >
            <MenuItem onClick={props.handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
        </Menu>
    );

}