import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AccountCircle, MenuBook } from '@mui/icons-material';
import { getAuth, signOut } from "firebase/auth"
import AccountMenu from './AccountMenu';
import BooksMenu from './BooksMenu';

interface ButtonAppBar {
  selectedBookTitle: null | string
  setSelectedBookTitle: (title: null | string) => void
}

export default function ButtonAppBar(props: ButtonAppBar) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorBooks, setAnchorBooks] = React.useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBooksMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorBooks(event.currentTarget);
  };

  const accountMenuId = 'account_menu_id';
  const booksMenuId = 'books_menu_id';

  const isAccountMenuOpen = Boolean(anchorEl);
  const isBooksMenuOpen = Boolean(anchorBooks);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorBooks(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    signOut(getAuth())
  };

  const setBook = (title: null | string) => {
    handleMenuClose();
    props.setSelectedBookTitle(title);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Random Highlight
          </Typography>

          <IconButton
            size="large"
            edge="end"
            aria-label="books"
            aria-controls={booksMenuId}
            aria-haspopup="true"
            onClick={handleBooksMenuOpen}
            color="inherit"
          >
            <MenuBook />
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={accountMenuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AccountMenu anchorEl={anchorEl} menuId={accountMenuId} isMenuOpen={isAccountMenuOpen} handleMenuClose={handleMenuClose} handleLogout={handleLogout} />
      <BooksMenu anchorEl={anchorBooks} menuId={booksMenuId} isMenuOpen={isBooksMenuOpen} handleMenuClose={handleMenuClose} setBook={setBook} selectedBookTitle={props.selectedBookTitle} />
    </Box>
  );
}